var checkoutRootElementsIds=["root_checkout_0", "root_checkout_1", "root_checkout_2"]

const checkout = {
    showCheckoutStep(showStep){
        for(var i=0;i<checkoutRootElementsIds.length;i++){
            let element=document.getElementById(checkoutRootElementsIds[i]);
            let elementBreadcrumb=document.getElementById("breadcrumb_checkout_"+i);
            if(i==showStep){
                element.style.display="flex";
            } else {
                element.style.display="none";
            }
    
            if(i<=showStep){
                elementBreadcrumb.classList.add("active");
                if(i<showStep){
                    for(const child of elementBreadcrumb.children){
                        if(child.tagName.toUpperCase()=="SPAN"){
                            //child.style.color="var(--white-color)";
                        }
                    }
                }
    
            } else {
                elementBreadcrumb.classList.remove("active");
            }
            console.log(showStep);
        }
    },

    checkoutNavClick(showStep){
        let elementBreadcrumb=document.getElementById("breadcrumb_checkout_"+showStep);
        if(elementBreadcrumb.classList.contains("active")){
            checkout.showCheckoutStep(showStep);
        }
    },
    
    setDeliveryAddressVisible(event){
        if(!document.getElementById("lieferadresse_question").checked){
            utils.objectToInput({},'#edit_adr','input[id^=LFA]');
            utils.objectToInput({},'#edit_adr','select[id^=LFA]');
            document.getElementById("select_lieferaddresse").value="choose";
        }
        $(".abweichende_lieferadresse").toggle();
    },
    
    checkoutSendAndPay() {
        utils.inputToObject(beleg,'#rechnungsadresse','input[id^=BEL]')
        var proms=[];
        showWait();
        shopApi.updateBeleg(beleg).then(data=>{
            checkout.saveDeliveryAddress().then(resp=>{
                hideWait();
                if(resp.success&&resp.data){
                    document.getElementById("error-root").style.display="none";
                    beleg=JSON.parse(resp.data);
                    let errors=$('#error-root').find('[id^=error_]');
                    if(errors){
                        console.log(errors);
                        errors.each(key=>{
                            errors[key].style.display="none";
                        });
                    }
                    checkout.showCheckoutStep(1);
                } else if(!resp.success){
                    document.getElementById("error-root").style.display="block";
                    let msg=resp.message;
                    if(resp.message3){
                        msg=resp.message3
                    } else if(resp.message2){
                        msg=resp.message2
                    }
    
                    document.getElementById(msg).style.display="block";
                }
                
                
            }, (error)=>{
                hideWait();
                //TODO mehr info!
                window.alert("Fehler beim Speicher");
            })
        });
      
    },
    
    resolveCountry(elementId, value, notfound=""){
        let deliveryAdrCountry=document.getElementById(elementId);
        if(deliveryAdrCountry){
            let found=false;
            for(var i=0;i<countries.length;i++){
                if(countries[i].DBK_0_60 == value){
                    deliveryAdrCountry.innerHTML=countries[i].DBK_180_60;
                    found=true;
                    break;
                }
            }
    
            if(!found){
                deliveryAdrCountry.innerHTML=notfound;
            }
        }
    },
    
    checkoutCheckAndOrder() {
        //TODO Klären ob hier was gemacht werden muss
        //z.B wenn mehrer Versandarten/zahlungsarten
        checkout.showCheckoutStep(2);
        utils.objectToElement(beleg,'#root_checkout_2','[id^=BEL]')
        checkout.resolveCountry("READR_COUNTRY_BEL_1789_3",beleg.BEL_1789_3);
        checkout.resolveCountry("DELADR_COUNTRY_BEL_4256_3",beleg.BEL_4256_3);
        checkout.resolveCountry("DELADR_COUNTRY_BEL_4256_3",beleg.BEL_4256_3);
        checkout.resolveCountry("DELARD_COUNTRY_BEL_1789_3",beleg.BEL_1789_3);
    
        if(beleg["BEL_4106_30"]&&beleg["BEL_4106_30"]!=""){
            document.getElementById("deliveryAddress").style.display="block"
            document.getElementById("deliveryAddressEqualsInvoicAddress").style.display="none"
        } else {
            document.getElementById("deliveryAddress").style.display="none"
            document.getElementById("deliveryAddressEqualsInvoicAddress").style.display="block"
        }
    
    },
    
    orderNow(){
        showWait();
        utils.inputToObject(beleg,'#root_checkout_2','input[id^=BEL]')
        shopApi.finishOrder(beleg).then(data=>{
            window.location="/de/order/finish";
        })
    },
    
    saveDeliveryAddress(){
        let useDifferentDeliveryAdr=document.getElementById("lieferadresse_question").checked;
        var cbAdr=$('#select_lieferaddresse');
        if(useDifferentDeliveryAdr&&cbAdr&&cbAdr.length>0){
            if(cbAdr[0].selectedIndex==1){
                let deliveryAddress={LFA_2_8:"-1"};
                utils.inputToObject(deliveryAddress,'#edit_adr','input[id^=LFA]')
                utils.inputToObject(deliveryAddress,'#edit_adr','select[id^=LFA]')
                return shopApi.createDeliveryAddress(deliveryAddress);
            } else if (cbAdr[0].selectedIndex>1){
                let id=cbAdr.find(":selected").val();
                let deliveryAddress=jQuery.extend({},checkout.getDeliveryAddressById(id));
                utils.inputToObject(deliveryAddress,'#edit_adr','input[id^=LFA]')
                utils.inputToObject(deliveryAddress,'#edit_adr','select[id^=LFA]')
                deliveryAddress["LFA_2_8"]=id;
                deliveryAddress["LFA_1077_40"]=deliveryAddress["LFA_120_30"];
                deliveryAddress["LFA_1031_46"]=deliveryAddress["LFA_80_30"];
                delete deliveryAddress.id;
                return shopApi.updateDeliveryAddress(deliveryAddress);
            }
        } else {
            return shopApi.updateDeliveryAddress({id:"-1"});
        }
    },
    
    getDeliveryAddressById(id){
        for(var i=0;i<deliveryAddresses.length;i++){
            if(deliveryAddresses[i].id==id){
                return deliveryAddresses[i];
            }
        }
    },
    
    fillDeliveryAddress(id){
        if(id=="newDeliveryAddress"){
            utils.objectToInput({},'#edit_adr','input[id^=LFA]')
            utils.objectToInput({},'#edit_adr','select[id^=LFA]')
        } else {
            if(deliveryAddresses){
                utils.objectToInput(checkout.getDeliveryAddressById(id),'#edit_adr','input[id^=LFA]')
                utils.objectToInput(checkout.getDeliveryAddressById(id),'#edit_adr','select[id^=LFA]')
            }
        }
    },
    
    acceptAgbandDSGVO(){
        let agb=document.getElementById("agb");
        let dsgvo=document.getElementById("privacy");
        let orderButton=document.getElementById("btn_order");
    
        let checkAgb=false;
        if(agb){
            checkAgb=agb.checked;
        }
    
        let checkDsgvo=false;
        if(dsgvo){
            checkDsgvo=dsgvo.checked;
        }
    
        if(orderButton){
            orderButton.disabled=!(checkAgb&&checkDsgvo);
        }
    },
}


export default checkout